<template>
  <div style="padding: 20px">
    <div class="dytitle">
      <span
        v-for="(item, index) in titlelist"
        :class="now == index ? 'dyactive' : ''"
        >{{ item }}</span
      >
    </div>
	
    <div class="num">
      订单编号：{{ num }}
      <span style="margin-right: 20px; cursor: pointer" @click="back"
        >返回列表</span
      >
    </div>
    <div>
      <steps :active="active"></steps>
      <trace :active="traceactive" :trace="trace"></trace>
      <articlecont :article="article"></articlecont>
      <div v-if="now == 0" class="bottombtn">
        <el-button @click="giveup">放弃投稿</el-button>
        <!--  <el-button type="danger" @click="charge" v-show="flag">点击缴费</el-button>-->
         <el-button type="danger" @click="charge" v-show="flag">继续投稿</el-button>
       </div>
       <div v-if="now == 1" class="bottombtn">
         <div v-show="show == 1">
           <el-button type="danger" @click="changeservice">更换服务商</el-button>
         </div>
         <div v-show="show == 0">
           <el-button @click="giveup">放弃投稿</el-button>

 <!--          <div v-show="show == 3">-->
        <el-button @click="drawback" v-show="flags">申请退款</el-button>
<!--          </div>-->
          <el-button @click="reuploade">重新上传</el-button>

          <el-button type="danger" @click="applyservice">申请服务商</el-button>
        </div>
      </div>
      <div v-if="now == 2" class="bottombtn">
        <el-button type="danger" @click="confirm()">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import steps from '../components/steps'
import trace from '../components/trace'
import articlecont from '../components/articlecont'
import { checksteps, checktrace, checkarticle } from "../../../API/QKD/detail.js"
import { 
giveupcontribution, //放弃投稿
confirmorder, // 
AddTransaction, // 本地缴费直接成功
applyfws, //申请服务商
applyRefund  
	} from "../../../API/ZZD/doreject.js"
export default {
  components: {
    steps,
    trace,
    articlecont,
  },
  data () {
    return {
      now: "",
      attr: "",
      num: '',
      active: 0,
      trace: [],
	  flag:true,// 投稿前 没有支付的时候显示缴费 如果支付成功 隐藏
 //     flags:true,// 投稿前 没有支付的时候显示缴费 如果支付成功 隐藏

      article: [],
      traceactive: 0,
      titlelist: ["待缴费", "被驳回", "待确认","已确认","历史订单","审核中"],
      show: false,
    }
  },
  mounted () {
    this.attr = this.$route.query.attr
    this.now = this.$route.query.now
    let id = this.$route.query.wid
    checksteps(id).then(res => {
      // console.log(res,'------------状态------------');
      this.num = res.data.data.contributionnumber
      //alert(res.data.data.state);
      if (res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 82) {
        this.active = 0
      } else
        if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 81 || res.data.data.state == 6 || res.data.data.state == 54 || res.data.data.state == 55 || res.data.data.state == 44) {
          this.active = 2
        } else if (res.data.data.state == 2 || res.data.data.state == 21 || res.data.data.state == 22) {
          this.active = 3
        } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 5) {
          this.active = 4
        } else if (res.data.data.state == 32 || res.data.data.state == 47) {
          this.active = 4
        } else {
          this.active = 5
        }


    })
    checktrace(id).then(res => {
      let result = res.data.data;
      for(let j = 0; j < result.length;j++){
        if(result[j].operation.includes("缴费成功")){
          let str = result[j].operation;
          let strRep = str.replace("缴费成功","投稿成功");
          result[j].operation = strRep;
        }
      }

      this.trace = result;
      // console.log(this.trace,'订单详细状态----------')
	  // 如果作者投稿失败 且 缴费成功 作者重新申请不回再次缴费
     // debugger;
      console.info("this.trace",this.trace);
	  for(var i = 0; i < res.data.data.length;i++){

        if(this.trace[i].operation=='期刊驳回'){
        //  alert(this.trace[i].operation);
       return    this.flags=true
        }
		  // if(this.trace[i].operation.indexof('缴费成功') >= 0 && this.trace[i].ispay == '1'){
			//   this.flag = false
		  // }

	  }
      this.traceactive = this.trace.length - 1

    })
    checkarticle(id).then(res => {
      this.article = res.data.data
    })
  },
  methods: {
    sereor () {
      if (this.$router.query.isUseFws == 0) {
        this.show = false
      } else if (this.$router.query.isUseFws == 1) {
        this.show = true
      }
    },
    back () {
      this.$router.push({
        path: "/Pending/todoorder",
        query: {
          "now": this.now,
          "id": 0
        }
      })
    },
    giveup () {
      let id = this.$route.query.wid
      this.$confirm('请确认是否放弃投稿', '放弃投稿', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        giveupcontribution(id).then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '已放弃投稿',
            });
            this.$router.push({
              path: "/Pending/todoorder",
              query: {
                "now": this.now,
                "id": 0
              }
            })
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    charge () {
      AddTransaction(this.$route.query.wid, this.num, 0, 0).then(res => {
        if (res.data.code == 0) {
          this.$message({
            type: 'success',
            message: '投稿成功，请耐心等待审核',
          });
          this.now = 5;
          this.$router.push({
            path: "/Pending/todoorder",
            query: {
              "now": this.now,
              "id": 0
            }
          })
        } else {
           this.$message(res.data.data);
        }

      })

		//跳转到支付页
    //   this.$router.push({
    //     path: "/Pending/todoorder/charge",
    //     query: {
    //       "wid": 2,
    //       "id": 0,
    //       num: this.num,
    //       id: this.$route.query.wid
    //     }
    //   })
	  //点击缴费直接成功
      // AddTransaction(this.$route.query.wid, this.num, 0, 0).then(res => {
      //   console.log(res.data,'支付成功')
      //   if (res.data.code == 0) {
      //     this.$message.success('支付成功');
      //     // this.$router.push({
      //     //   path: "/Pending/todoorder",
      //     // })
      //   } else {
      //     this.$message(res.data.data);
      //   }
      // })
    },
    reuploade () {
      this.$router.push({
        path: "/Pending/contributelist",
        query: {
          "wid": 2,
          "id": 0,
          works_id: this.$route.query.wid
        }
      })
    }, 
	drawback () {
      applyRefund(this.$route.query.wid).then(res => {
        if (res.data.code == 0) {
          this.$message(res.data.data);
          this.$router.push({
            path: "/Pending/todoorder",
          })
        } else {
          this.$message(res.data.data);
        }
      })
      this.$confirm('工作人员将于24小时内进行退款处理！', '申请退款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '提交成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }, 
	changeservice () {
      applyfws(this.$route.query.wid).then(res => {
        if (res.data.code == 0) {
          this.$message(res.data.msg);
          this.$router.push({
            path: "/Pending/todoorder",

          })
        } else {
          this.$message(res.data.msg);
        }
      })
      // this.$confirm('平台将重新安排服务商，确定更换吗？', '更换服务商', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: '申请成功!'
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    }, applyservice () {
      // this.$router.push({
      //   path: "/Pending/todoorder/applyservice",
      //   query: {
      //     "wid": 2,
      //     "id": 0,
      //     "num": this.num,
      //     works_id: this.$route.query.wid
      //   }
      // })
      applyfws(this.$route.query.wid).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message(res.data.msg);
          this.$router.push({
            path: "/Pending/todoorder",

          })
        } else {
          this.$message(res.data.msg);
        }
      })
    }, confirm () {//订单确认
      confirmorder(this.$route.query.wid).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message.success("确认成功")
          history.back()
        }
      })
    }
  }
}
</script>

<style>
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.detailBottom {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.dytitle {
  border-bottom: 1px solid #f7f7f7;
  font-size: 14px;
  line-height: 60px;
}

.dytitle span {
  margin: 0 10px;
  padding: 0 5px;
  display: inline-block;
}

.dyactive {
  border-bottom: 1px solid red;
  margin: 0 10px;
  padding: 0 5px;
  display: inline-block;
  color: red;
}

.bottombtn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
</style>